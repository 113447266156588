import React, { useCallback } from 'react';
import { createPlugin } from 'react-plugin';
import { SelectValueInput } from './SelectValueInput.js';
const { plug, register } = createPlugin({
    name: 'controlSelect',
});
plug('control-select', ({ slotProps }) => {
    const { controlName, control, onFixtureStateChange } = slotProps;
    const handleChange = useCallback((selectName, updatedControl) => {
        onFixtureStateChange(prevFs => ({
            ...prevFs,
            [selectName]: updatedControl,
        }));
    }, [onFixtureStateChange]);
    return (React.createElement(SelectValueInput, { key: controlName, name: controlName, control: control, onChange: handleChange }));
});
export { register };
if (process.env.NODE_ENV !== 'test')
    register();

import React from 'react';
import { Label, ValueDataContainer, ValueInputContainer, } from '../../components/ValueInputTree/ValueInput/shared.js';
import { Select } from '../../components/inputs/Select.js';
import { lightBlue } from '../../style/colors.js';
export function SelectValueInput({ name, control, onChange }) {
    const { options, currentValue } = control;
    const id = `select-${name}`;
    return (React.createElement(ValueInputContainer, { key: name },
        React.createElement(Label, { title: name, htmlFor: id }, name),
        React.createElement(ValueDataContainer, null,
            React.createElement(Select, { id: id, options: options.map(option => ({ value: option, label: option })), value: currentValue, color: lightBlue, height: 24, padding: 5, onChange: newValue => onChange(name, {
                    ...control,
                    currentValue: newValue.value,
                }) }))));
}

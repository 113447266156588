export const DEFAULT_PLUGIN_CONFIG = {
    root: {
        sidePanelRowOrder: ['values', 'props', 'classState'],
        globalActionOrder: ['remoteRenderer'],
        globalOrder: ['fixtureSearch', 'notifications'],
        navRowOrder: ['fixtureSearch', 'fixtureBookmarks', 'fixtureTree'],
        fixtureActionOrder: ['bookmarkFixture'],
        rendererActionOrder: ['editFixture', 'fullScreen', 'responsivePreview'],
    },
    controlPanel: {
        controlActionOrder: ['expandCollapse'],
    },
};

import { isEqual } from 'lodash-es';
import React from 'react';
import { SidePanelActions, SidePanelBody, SidePanelContainer, SidePanelHeader, SidePanelTitle, } from '../../components/SidePanel.js';
import { IconButton32 } from '../../components/buttons/index.js';
import { RotateCcwIcon } from '../../components/icons/index.js';
import { ControlActionSlot } from '../../slots/ControlActionSlot.js';
import { ControlSlot } from '../../slots/ControlSlot.js';
export function ControlPanel({ fixtureState, controlActionOrder, onFixtureStateChange, }) {
    const handleControlsReset = React.useCallback(() => onFixtureStateChange(resetControls), [onFixtureStateChange]);
    const controls = fixtureState ?? {};
    if (Object.keys(controls).length === 0)
        return null;
    return (React.createElement(SidePanelContainer, null,
        React.createElement(SidePanelHeader, null,
            React.createElement(SidePanelTitle, { label: "Controls" }),
            React.createElement(SidePanelActions, null,
                React.createElement(IconButton32, { title: "Reset to default values", icon: React.createElement(RotateCcwIcon, null), disabled: areControlsUnchanged(controls), onClick: handleControlsReset }),
                React.createElement(ControlActionSlot, { slotProps: { controls }, plugOrder: controlActionOrder }))),
        React.createElement(SidePanelBody, null, Object.keys(controls).map(controlName => (React.createElement(ControlSlot, { key: controlName, slotProps: {
                controlName,
                control: controls[controlName],
                onFixtureStateChange,
            } }))))));
}
function areControlsUnchanged(controls) {
    return Object.keys(controls).every(controlName => isEqual(controls[controlName].currentValue, controls[controlName].defaultValue));
}
function resetControls(fixtureState) {
    const controls = fixtureState ? { ...fixtureState } : {};
    Object.keys(controls).forEach(controlName => {
        controls[controlName] = resetControl(controls[controlName]);
    });
    return controls;
}
function resetControl(control) {
    return { ...control, currentValue: control.defaultValue };
}
